<template>
  <div v-if="product" class="shop-product-controls" :class="[`is-${variant}`]">
    <div class="shop-product-controls__group">
      <div v-if="hasHelpedIcon" class="shop-product-controls__icon">
        <AppIcon
          icon="helped"
          :is-raw="true"
          @click="markAsHelped($event)"
          @keypress="markAsHelped($event)"
        />
      </div>
      <div v-if="hasLovedIcon" class="shop-product-controls__icon">
        <AppIcon
          icon="loved"
          :is-raw="true"
          @click="markAsLoved($event)"
          @keypress="markAsLoved($event)"
        />
      </div>
    </div>

    <div class="shop-product-controls__group">
      <div class="shop-product-controls__icon">
        <AppIcon
          v-if="hasBookmarkIcon && isBookmarked"
          icon="bookmark-remove"
          :is-raw="true"
          @click="toggleBookmark($event)"
          @keypress="toggleBookmark($event)"
        />
        <AppIcon
          v-else-if="hasBookmarkIcon && !isBookmarked"
          icon="save"
          :is-raw="true"
          @click="toggleBookmark($event)"
          @keypress="toggleBookmark($event)"
        />
      </div>

      <AppIcon
        v-if="computedHasCalendarIcon"
        icon="calendar"
        :is-raw="true"
        @click="addToCalendar($event)"
        @keypress="addToCalendar($event)"
      />
      <AppIcon
        v-if="hasCartIcon"
        icon="cart-small"
        :is-raw="true"
        @click="addToCart($event, true)"
        @keypress="addToCart($event, true)"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    hasBookmarkIcon: {
        type: Boolean,
        default: true,
    },

    hasCartIcon: {
        type: Boolean,
        default: true,
    },

    hasLovedIcon: {
        type: Boolean,
        default: false,
    },

    hasHelpedIcon: {
        type: Boolean,
        default: false,
    },

    hasCalendarIcon: {
        type: Boolean,
        default: false,
    },

});

const variant = inject('variant');

/*
    Cart
*/
const cartStore = useCartStore();
const isPopupDisabled = inject('is-popup-disabled');
const product = inject('item');

const addToCart = ($event, isAdd = true) => {
    $event.stopPropagation();

    const productToAdd = product?.value || product;
    cartStore.addProduct(productToAdd, {
        openPopup: isPopupDisabled?.value !== true,
        isAdd,
    });
};

/*
    Calendar
*/
const {
    parseEventDates,
    downloadIcs,
    generateIcs,
} = useICS();

const addToCalendar = ($event) => {
    $event.stopPropagation();

    /* pase dates from item */
    const dates = parseEventDates(product);

    /* generate ics file */
    const ics = generateIcs(dates, product);

    /* if ics ics generation is sucessfull download the ics */
    if (ics && dates.length > 0) {
        downloadIcs(ics);
    }
};

const computedHasCalendarIcon = computed(() => {
    const dates = parseEventDates(product);

    if (dates.length > 0 && props.hasCalendarIcon) {
        return true;
    }

    return false;
});

/*
    Bookmarks
*/
const bookmarkStore = useBookmarkStore();

const isBookmarked = computed(() => {
    if (product) {
        return bookmarkStore.itemIsBookmarked(product?.value || product);
    }

    return false;
});

const toggleBookmark = ($event) => {
    $event.stopPropagation();

    if (isBookmarked.value) {
        bookmarkStore.removeBookmark(product?.value || product);
    } else {
        bookmarkStore.addBookmark(product?.value || product);
    }
};

/*
    Helped
*/
const markAsHelped = ($event) => {
    $event.stopPropagation();
    // Out of MVP. Implement logic later here
};

/*
    Loved
*/
const markAsLoved = ($event) => {
    $event.stopPropagation();
    // Out of MVP. Implement logic later here
};
</script>

<style lang="scss">
.shop-product-controls {
    display: flex;
    width: 100%;
    height: 24px;

    .block-hero-products &, .block-hero-events &  {
        height: 100%;
    }
}

.shop-product-controls__group {
    display: flex;
    flex-basis: 50%;
    column-gap: 20px;

    .block-hero-products &, .block-hero-events {
        flex-basis: unset;
        column-gap: 0;
    }

    svg {
        display: block;
        width: 24px;
        height: 24px;

        path {
            .is-campaign-rose & {
                fill: $C_PINK;
            }

            .is-campaign-orange & {
                fill: $C_ORANGE_STRONG;
            }

            .is-tipp & {
                fill: $C_ORANGE;
            }

            .is-newsletter {
                fill: $C_BLUE;
            }

        }
    }

    .shop-product-controls__icon {
        .block-hero-products &, .block-hero-events &, .block-hero-consultations & {
            @include fluid('height', 46px, 54px);
            @include fluid('padding-left', 11px, 20px);
            @include fluid('padding-right', 11px, 20px);

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            margin-right: 10px;
            background: $C_WHITE;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &:last-child {
        justify-content: flex-end;
    }
}
</style>
