import * as ics from 'ics';

const { dayjs } = useDayJs();

export default () => {
    const parseEventDates = (event) => {
        if (!event?.dates) return [];

        const dates = event.dates?.map((date) => ({
            start: date.date,
            end: date.end_date,
        }));

        /* Filter out dates in the past */
        const now = dayjs();
        const filteredDates = dates?.filter((date) => {
            const dayjsDate = dayjs(date.start);
            const isAfter = dayjsDate.isAfter(now);

            return isAfter;
        });

        return filteredDates;
    };

    const downloadIcs = (icsString) => {
        window.open(`data:text/calendar;charset=utf8,${escape(icsString)}`);
    };

    const generateIcs = (dates, eventData) => {
        /* Map dates to a format that ics can understand */
        const mapped = dates.map((date) => {
            const startDate = dayjs(date.start);
            const endDate = dayjs(date.end);
            const { location } = eventData;
            const parsed = {
                title: replaceUmlauts(eventData.title),
                description: replaceUmlauts(eventData.ics_description),
                location: `${replaceUmlauts(location?.address)}, ${location?.zip} ${replaceUmlauts(location?.place)}`,
                start: [
                    startDate.$y,
                    startDate.$M + 1,
                    startDate.$D,
                    startDate.$H,
                    startDate.$m,
                ],
                end: [
                    endDate.$y,
                    endDate.$M + 1,
                    endDate.$D,
                    endDate.$H,
                    endDate.$m,
                ],
            };

            if (location?.url) {
                parsed.url = location.url;
            }

            if (location?.lat && location?.long) {
                parsed.geo = {
                    lat: parseFloat(location.lat),
                    lon: parseFloat(location.long),
                };
            }

            return parsed;
        });

        const { error, value } = ics.createEvents(mapped);

        if (error) {
            useSentryError(error);
            return false;
        }

        return value;
    };

    return {
        generateIcs,
        downloadIcs,
        parseEventDates,
    };
};
